import React from "react";
import Template from "../../components/Template";
import Sitemap from '../../components/Navbar/sitemap.json';

const content = {
  "meta": {
    "title": "Logic Platform™",
  },
  "header": {
    "title": <>Logic Platform™</>,
    "description": "At Logictry, we are on a mission to scale expertise and unlock better decisions in your personal life, in your professional life, and in the world.",
    "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/26370f3083e00338fddf35056235cbf0/Screenshot%202024-02-02%20at%204.10.33%E2%80%AFPM.png",
  },
  "main": [
    {
      "title": <>Simple No-Code Editor</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/7c037208fbded325183401482586d9a0/nocodeeditor.png",
    },
    {
      "title": <>Embed in SalesForce</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/6916e8a44b943c7a5b41a0361fb982c8/embedsalesforce.png",
    },
    {
      "title": <>Embed in Your Website</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/aeecb5426ca81276ff6e1b998e9f3dc8/embedwebsite.png",
    },
    {
      "title": <>Build / Share / Embed</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/4f55d3afc65ea13da97442ec68d6c93e/fiesta.png",
    },
    {
      "title": <>Build / Share / Embed</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/8607285965abc1c20f9da93f0c4585bb/coloradoinformed.png",
    }
  ],
}

const LogicPlatform = () => {
  return (
    <Template content={content} />
  )
};
export default LogicPlatform;
