import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "World Logic Forum",
  },
  "header": {
    "title": "World Logic Forum",
    "description": "Learn more about the World Logic Forum",
    "youtube": "https://www.youtube.com/embed/7iK9BFk9_3Y?si=TSLxDkOD4Z9akfgW",
    "cta": {
      "title": "Learn more!",
      "link": "https://worldlogicday.com/"
    }
  },
  "main": [
    {
      "title": <>Leaving a Legacy with Andrea Virgin</>,
      "youtube": "https://www.youtube.com/embed/72jkNJZNLHI?si=Q0gOQ2sACL6yuTv1",
    },
    {
      "title": <>Leaving a Legacy with Mary Ann Thompson Frenk</>,
      "youtube": "https://www.youtube.com/embed/fzMQgEjPotc?si=0HbYlzxz-LnVJyqW",
    },
    {
      "title": <>Leaving a Legacy with Imogen Morall</>,
      "youtube": "https://www.youtube.com/embed/tG6oVp8UVek?si=ZcJsbodmDyO4QwNp",
    },
    {
      "title": <>Leaving a Legacy with Peter Loukianoff</>,
      "youtube": "https://www.youtube.com/embed/yLiZPT2Ma1Q?si=nNiWobE-kZtJRAjL",
    },
    {
      "title": <>Leaving a Legacy with Natalie Meyer</>,
      "youtube": "https://www.youtube.com/embed/aZ8Scq3cs1k?si=i1eau-I2GW-qn-P0",
    },
    {
      "title": <>Leaving a Legacy with Nathan Otto</>,
      "youtube": "https://www.youtube.com/embed/LAKHtgicbAo?si=7zsvK08X7rtXIvNB",
    },
    {
      "title": <>Leaving a Legacy with Yisrael Rabinowitz</>,
      "youtube": "https://www.youtube.com/embed/Q6hAFcIKAQQ?si=pPwRyroIwKlaaW7b",
    },
    {
      "title": <>Leaving a Legacy with Chris Fronda</>,
      "youtube": "https://www.youtube.com/embed/rJf-ueSaDBY?si=yrfiDjN2ToIWCt3G",
    },
    {
      "title": <>Philanthropy & Equal Access with Brooke Axtell & Constance Dykhuizen</>,
      "youtube": "https://www.youtube.com/embed/e08We7RPKXo?si=PZ2c7DejhXedP95z",
    },
    {
      "title": <>Future of Media & Content</>,
      "youtube": "https://www.youtube.com/embed/eC8X6SmFcJw?si=9p1eKfVAcO_WWoUS",
    },
    {
      "title": <>Future of Mental Health, Policy, Governance & Youth</>,
      "youtube": "https://www.youtube.com/embed/nj_W6a2lCpg?si=diQCNuEW8-yobkZa",
    },
    {
      "title": <>Future of Community & Technology</>,
      "youtube": "https://www.youtube.com/embed/PZxTDy3n3CE?si=R0JXE2pTXw43kogh",
    },
    {
      "title": <>Future of Education</>,
      "youtube": "https://www.youtube.com/embed/yBd1zmcqf6Y?si=fQeqFWM82-EOG9yY",
    },
  ],
}

const WorldLogicForum = () => {
  return (
    <Template content={content} />
  )
};
export default WorldLogicForum;
