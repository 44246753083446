import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Calculators",
  },
  "header": {
    "title": <><span>Interactive</span> Calculators</>,
    "description": "Utilize AI-driven calculators to enhance customer engagement, pricing strategies, and ROI conversions.",
    "app": "/forecastr-paid-ads-roi-calculator"
  },
  "main": [
    {
      "title": "ROI Calculators",
      "description": "Use ROI calculators to enhance customer conversions by demonstrating the value of your services.",
      "app": "/apps/6328364e47ea2a07c676747b"
    },
    {
      "title": "Engagement Calculators",
      "description": "Offer guided engagement calculators to help customers predict and measure success metrics.",
      "app": "/hootsuite-engagement-rate-calculator"
    },
    {
      "title": "Pricing Calculators",
      "description": "Simplify pricing for your services with detailed and powerful pricing calculators.",
      "app": "/apps/632837c347ea2a07c676747c"
    }
  ],
  "caseStudies": [
    "conversion-sciences-roi-calculator",
  ]
}

const Calculators = () => {
  return (
    <Template content={content} />
  )
};
export default Calculators;
