import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Sales Playbooks",
  },
  "header": {
    "title": <><span>Interactive</span> Sales Playbooks</>,
    "description": "Discover the next generation of FAQs",
  },
  "main": [
    {
      "title": "AI-Enabled Smart FAQs",
      "description": "Interactive content and AI-Enabled Chatbot",
      "apps": ["/apps/66b25fede559423de19e7020?onclickshowfullscreen=false&onlyscrollfullscreen=false", "/apps/66b25fede559423de19e7020?onclickshowfullscreen=false&onlyscrollfullscreen=false&showchatbot=true"]
    },
    {
      "title": "AI-Enabled Smart FAQs",
      "description": "Interactive content and AI-Enabled Chatbot",
      "app": "/apps/5f32c99b9594e81fdedf97ca"
    },
  ],
}

const SalesPlaybooks = () => {
  return (
    <Template content={content} />
  )
};
export default SalesPlaybooks;
