import React from "react";
import Template from "../../components/Template";
import Sitemap from '../../components/Navbar/sitemap.json';

const content = {
  "meta": {
    "title": "Interactive Content is the Future",
  },
  "header": {
    "title": <><span>Interactive Content</span> Collections</>,
    "description": <>Embed collections of interactive resources on your website.</>,
    "app": "/collections/startups"
  },
  "main": [
    {
      "title": "Politics Collections (Grid Layout)",
      "app": "/collections/politics?contentlayout=grid"
    },
    {
      "title": "Shopping Collections (Grid Layout)",
      "app": "/collections/product-selection?contentlayout=grid"
    },
  ],
}

const Collections = () => {
  return (
    <Template content={content} />
  )
};
export default Collections;
