import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Custom Branded Curated Directories"
  },
  "header": {
    "title": <>Custom Branded <span>Curated</span> Directories</>,
    "description": "Create custom-branded, curated, and filterable directories to enhance searchability and organization.",
    "app": "/fiesta-austin-accelerators-and-incubators"
  },
  "main": [
    {
      "title": "Prompting Directories",
      "description": "Develop directories for AI chatbot prompts and best practices, enabling users to search and filter for effective chatbot training resources.",
      "app": "/troophr-prompting-pro-tips"
    },
    {
      "title": "Student Organization Directories",
      "description": "Create directories for student organizations, complete with advanced filtering options to help users easily find and connect with groups of interest.",
      "app": "/apps/5f6cecf1a96d9b13100b876f"
    },
    {
      "title": "Fund Directories",
      "description": "Build detailed directories for venture capital funds, allowing users to filter and search for specific investment opportunities and fund types.",
      "app": "/bluevine-750-seed-fund-list"
    },
    {
      "title": "Service Provider Directories",
      "description": "Design directories for various service providers, with filter options to help users find the most suitable providers based on their specific needs.",
      "app": "/fiesta-service-providers"
    }
  ],
  "caseStudies": [
    "fiesta-community-resource-directory",
    "onlinemeded-training-directories"
  ]
}


const Directories = () => {
  return (
    <Template content={content} />
  )
};
export default Directories;
