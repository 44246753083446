import React from "react";
import styled from 'styled-components';
import Navbar from "../Navbar";
import Header from "../../styledhtml/Header";
import Footer from "../Footer";
import { useParams, useLocation, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { device } from "../../submodules/logictry_common/src/utils/device";

const NewsHeader = styled.header`
  padding: 12rem 2rem 0;
  @media ${device.laptop} {
    padding: 8rem 2rem 0;
  }
  margin: 2rem auto;
  max-width: 1000px;
  flex-direction: column;
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  img {
    width: 100%;
    border-radius: 1rem;
  }
`
const Main = styled.main`
  position: relative;
  > section {
    padding: 0 2rem;
    max-width: 1000px;
    margin: 0rem auto 4rem;
  }
  img {
    max-width: 100%;
    height: unset;
    margin: auto;
    display: block;
  }
  iframe {
    width: 100%;
    height: 600px;
    border-radius: 0.5rem;
    background: white;
    box-shadow: 0 .9375rem 3.125rem 0 rgba(0,0,0,0.3);
    margin: 1rem 0 2rem;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  blockquote {
    padding: 0.5rem 1rem;
  }
`;
const NewsGallery = styled.main`
  margin: 2rem auto 4rem;
  > section {
    margin: 0 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    > a {
      width: 320px;
      display: block;
      text-decoration: none;
      :hover {
        text-decoration: underline;
      }
      > div > div {
        padding: 0 1rem;
      }
    }
    text-align: center;
  }
  img {
    height: 180px;
    width: 320px;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

const Posts = ({ TITLE, DESCRIPTION, CONTENT }) => {
  const { id } = useParams();
  const location = useLocation();
  const content = CONTENT[id];
  if (!content) {
    return (
      <>
        <Helmet>
          <title>Logictry {TITLE}</title>
        </Helmet>
        <Navbar />
        <Header>
          <h1>{TITLE}</h1>
          {/* <p>{DESCRIPTION}</p> */}
        </Header>
        <NewsGallery>
          <section>
            {Object.entries(CONTENT).map(([url, { title, image }]) => (
              <NavLink key={url} to={`${location.pathname}/${url}`}>
                <div>
                  <img src={image} alt={title} />
                  <div>{title}</div>
                </div>
              </NavLink>
            ))}
          </section>
        </NewsGallery>
        <Footer />
      </>
    )
    
  }

  const { title, image, blog, youtube } = content;
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Navbar />
      <NewsHeader>
        {youtube && <div style={{ width: '100%', paddingBottom: '56.5%', position: 'relative' }}>
          <iframe
            style={{ position: 'absolute', inset: 0, width: '100%', height: '100%', borderRadius: '1rem' }}
            src={`${youtube}&rel=0&autoplay=0&controls=1&loop=1&mute=0&playsinline=1&modestbranding=1`}
            title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div> || image && <div style={{ borderRadius: '1rem', width: '100%', height: 0, paddingBottom: '56.5%', background: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>}
        <h1>{title}</h1>
      </NewsHeader>
      <Main>
        <section>
          {blog}
        </section>
      </Main>
      <Footer />
    </>
  )
};
export default Posts;
