import React from "react";
import Template from "../../components/Template";
import Sitemap from '../../components/Navbar/sitemap.json';

const content = {
  "meta": {
    "title": "Interactive Content is the Future",
  },
  "header": {
    "title": <><span>Interactive Content</span> is the Future</>,
    "description": <>Transform outdated static content into dynamic, interactive content that delivers personalized, actionable recommendations—all created effortlessly using our no-code editor.</>,
    "app": "/apps/624eb45c7429f81fe0a5440a"
  },
  "main": [
    {
      "title": "Fun Quizzes and Assessments",
      "description": <><span>52.6%</span> higher engagement rates with interactive content compared to static content.</>,
      "link": Sitemap.Quizzes,
      "app": "/openai-should-i-vote-republican-or-democrat"
    },
    {
      "title": "Interactive Buying Guides",
      "description": <><span>70%</span> increased buyer conversion with interactive content compared to static content.</>,
      "link": Sitemap.BuyingGuides,
      "app": "/chrisfronda-timex-buying-guides"
    },
    {
      "title": "Smart FAQs and Chatbots",
      "description": "Create powerful chatbots to answer questions based on the training and answers you provide.",
      "link": Sitemap.Chatbots,
      "app": "/apps/66b25fede559423de19e7020?showchatbot=true",
    },
    {
      "title": "Interactive Handbooks, Playbooks, and SOP(s)",
      "description": <><span>51%</span> of B2B buyers say interactive content is helpful when tackling business challenges.</>,
      "link": Sitemap.SalesPlaybooks,
      "app": "/apps/64ce7bc129bc980dbe5a16b9",
    },
    {
      "title": <>Simple No-Code Editor</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/40a4c4eff8ece0b5690f67e83262b7ac/no-code-editor.png",
    },
    {
      "title": <>Embed in Your Website</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/173072f78a18b8b7552bdfde2325b15e/embed-website.png",
    },
    {
      "title": <>Embed in SalesForce</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/a93799d670ea66c113fd2c9e6eb0148d/embed-salesforce.png",
    },
  ],
}

const Home = () => {
  return (
    <Template content={content} />
  )
};
export default Home;
