import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import { device } from "../../submodules/logictry_common/src/utils/device";

export default styled.header`
  padding: 12rem 2rem 4rem;
  @media ${device.laptop} {
    padding: 8rem 2rem 4rem
  }
  margin: 2rem 5%;
  display: flex;
  align-items: center;
  gap: 4rem;
  @media ${device.laptopL} {
    margin: 2rem auto;
    max-width: 1440px;
  }
  @media ${device.laptop} {
    flex-direction: column;
    align-items: flex-start;
  }
  h1 {
    margin: auto auto 1rem;
    font-size: 2.4rem;
    @media ${device.tablet} {
      font-size: 2.4rem;
    }
    @media ${device.mobileL} {
      font-size: 1.8rem;
    }
    font-weight: 500;
    span {
      background-color: #ff5f00;
      color: white;
      padding: 0 0.5rem;
      margin: 0 0;
      border-radius: 0.5rem;
    }
  }
  p {
    color: #505256;
    font-size: 1.3rem;
    @media ${device.tablet} {
      font-size: 1.2rem;
    }
    @media ${device.mobileL} {
      font-size: 1.1rem;
    }
    span {
      background-color: ${Constants.PrimaryColor};
      color: white;
      padding: 0.25rem 0.25rem;
      margin: -0.25rem 0rem;
      border-radius: 0.5rem;
      font-weight: 600;
    }
  }
  button {
    padding: 1rem 2rem;
    font-size: 1.3rem;
    background: #1ebd53;
    border-radius: 2rem;
    color: white;
    font-weight: 600;
    margin-top: 1.5rem;
  }
  > div:first-child {
    flex: 2;
    @media ${device.laptopL} {
      flex: 2;
    }
    max-width: 36rem;
  }
  > div:last-child {
    flex: 3;
    @media ${device.laptopL} {
      flex: 3;
    }
    width: 100%;
  }
  iframe {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%;
  }
`;
