import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive White Papers",
  },
  "header": {
    "title": <><span>Interactive</span> White Papers</>,
    "description": "Use AI to build product recommendation guides",
    "app": "/nycschools-ready-new-york-choose-your-own-path-to-preparedness"
  },
  "main": [
    {
      "title": "Decision Tree Example",
      "description": "The original static content and the converted interactive content",
      "apps": [
        "/apps/66d3565b95166bc0e1149859", "/nycschools-ready-new-york-choose-your-own-path-to-preparedness"],
      "cta": ""
    },
  ],
}

const WhitePapers = () => {
  return (
    <Template content={content} />
  )
};
export default WhitePapers;
