import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Make Logic Possible™",
  },
  "header": {
    "title": "Make Logic Possible™",
    "description": "We believe Logic is the key to solving some of the world's biggest problems.",
    "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/cb6fbaac7cdb963bcf340a313c0f7d90/66abcf3282f9eae19592d0d6_builtinlogictry.png"
  },
  "main": [
    {
      "title": <>KVUE - Logic Creates Better Collaboration</>,
      "description": "A global effort celebrating the importance of using logic to address all the things going on in the world.",
      "youtube": "https://www.youtube.com/embed/h0594vm9iwA?si=5Lr_yADQBcWKNdL7"
    },
    {
      "title": <>KXAN - Logic Creates More Opportunity</>,
      "description": "A global initiative that celebrates the importance of logic in addressing challenges world wide.",
      "youtube": "https://www.youtube.com/embed/VO3wvYd5u4o?si=TnunoafLNaPeqreV",
    },
    {
      "title": <>World Logic Day 2024 - Chris Fronda</>,
      "youtube": "https://www.youtube.com/embed/WhWIp7G0YIs",
    },
    {
      "title": <>World Logic Day 2024 - Chelsea Toler</>,
      "youtube": "https://www.youtube.com/embed/JDZGFcOFOAM",
    },
  ],
}

const MakeLogicPossible = () => {
  return (
    <Template content={content} />
  )
};
export default MakeLogicPossible;
