import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Create Your Interactive Knowledge Base",
  },
  "header": {
    "title": <>Create Your <span>Interactive</span> Knowledge Base</>,
    "description": "We turn a complex sales process into an easy button.",
    "youtube": "https://www.youtube.com/embed/YOvuO7TMILE"
  },
  "main": [
    {
      "title": "The Future of Sales",
      "description": <><span>59%</span> less employee turnover from companies who practice a more engaging workforce.</>,
      "loom": "https://www.loom.com/embed/a2784a834b494e0187bf7affa98e4b4c?sid=e607b216-59b5-4d49-a315-dd425e1b9bf6"
    },
  ],
}

const KnowledgeTeams = () => {
  return (
    <Template content={content} />
  )
};
export default KnowledgeTeams;
