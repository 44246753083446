import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Artificial Intelligence with Experts in-the-loop",
  },
  "header": {
    "title": <>Artificial Intelligence with <span>Experts</span> in-the-loop</>,
    "description": <>From personalized learning to real-time decision support, the future is interactive AI-assisted intelligence.</>,
    "app": "/apps/624eb45c7429f81fe0a5440a",
  },
  "main": [
    {
      "title": "Interactive and AI-Enabled Smart FAQs",
      "description": <><span>51%</span> of B2B buyers say interactive content is helpful when tackling business challenges.</>,
      "apps": ["/apps/66b25fede559423de19e7020?onclickshowfullscreen=false&onlyscrollfullscreen=false", "/apps/66b25fede559423de19e7020?onclickshowfullscreen=false&onlyscrollfullscreen=false&showchatbot=true"]
    },
    {
      "title": "Interactive and AI-Enabled Handbooks",
      "description": <><span>56%</span> of businesses cite chatbot technology as a transformative force.</>,
      "apps": ["/apps/64ce7bc129bc980dbe5a16b9?onclickshowfullscreen=false&onlyscrollfullscreen=false", "/apps/64ce7bc129bc980dbe5a16b9?onclickshowfullscreen=false&onlyscrollfullscreen=false&showchatbot=true"]
    },
  ],
}

const ArtificialIntelligence = () => {
  return (
    <Template content={content} />
  )
};
export default ArtificialIntelligence;
