import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "World Logic Day",
  },
  "header": {
    "title": "World Logic Day",
    "description": "Learn more about the United Nations World Logic Day",
    "youtube": "https://www.youtube.com/embed/z7Wt25nWu-c?si=5QiOLaeJH90oCgRY",
    "cta": {
      "title": "Learn more!",
      "link": "https://worldlogicday.com/"
    }
  },
  "main": [
    {
      "title": <>The Logic of Global Collaboration at scale</>,
      "description": "Listen to futurist Indy Johar and Chelsea Toler discuss the importance of global collaboration.",
      "youtube": "https://www.youtube.com/embed/NbD4u27qvog?si=cRpSuDv8GNV2ESRi",
    },
    {
      "title": <>The Founding of World Logic Day</>,
      "description": "Listen to swiss logician Dr. Jean-Yves Beziau discuss why he founded of World Logic Day ",
      "youtube": "https://www.youtube.com/embed/HUsZ9dlZyHY?si=AgCedkjwKXwPGYoD",
    },
    {
      "title": <>Artificial Intelligence with Experts in-the-loop</>,
      "description": "Discover how integrating expert oversight into AI systems ensures ethical decision-making, enhances innovation, and protects consumer interests in today's business landscape.",
      "youtube": "https://www.youtube.com/embed/3ypSlkl_Rf0?si=bQEALsLKkWcnq1Cq"
    },
    {
      "title": <>Make Better Decisions Faster™</>,
      "description": "At Logictry, we are on a mission to scale expertise and unlock better decisions in your personal life, in your professional life, and in the world.",
      "youtube": "https://www.youtube.com/embed/vvM32dMMxCY?si=ww0buaV576hyjxN8"
    }
  ],
}

const WorldLogicDay = () => {
  return (
    <Template content={content} />
  )
};
export default WorldLogicDay;
