const caseStudies = {
  'national-instruments-sales-distributor-and-partner-enablement': {
    title: 'National Instruments - Sales, Distributor, and Partner Enablement',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/a012c7e899e7b7f3a953f9d790cf1145/66abcf3282f9eae19592d139_ni.jpg',
    blog: <>
      <p>Logictry's technology empowers organizations like National Instruments to capture all of the Solution Engineering technical knowledge into a product that gives junior sellers the "Easy Button".</p>
      <blockquote>"What had taken 2 weeks of back-and-forth with the product experts and the customer, I was able to get to the same product recommendations in 4 minutes with 4 questions. Incredible!" - Eric Wang, Account Manager at NI</blockquote>
      <p>Logictry worked with a top performer at National Instruments to build a powerful sales configurator capable of walking any junior seller through even the most complex sale with 100% accuracy.</p>
      <blockquote>"It was 100% accurate compared to sellers building configurations alone. Catching all the interdependencies is what makes the logic in Logictry powerful." - Penny Merian, Chief Product Marketer at NI</blockquote>
      <h2>Sales Enablement - Sales Configurator</h2>
      <p><b>DEMO:</b> Try typing the words: "Data Logging 8106"</p>
      <p><iframe src="https://logictry.com/apps/5e41733f18397e3927db88ee?autoresize=false"></iframe></p>
      <p>National Instruments (NI) has revolutionized its distributor enablement process by leveraging Logictry's No-Code Interactive Content platform to build robust tools. These innovative tools, including EOL product replacement guides, part number finders, and partner recommendation tools, have significantly enhanced NI's distribution network.</p>
      <blockquote>"In all my years of working with our distributors, I’ve never seen such a positive reaction." - Joshua Landau, Partner Strategy Execution Manager at National Instruments</blockquote>
      <h2>Distributor Enablement - Part Number Finder</h2>
      <p>NI turned a static spreadsheet into this shareable Interactive Content.</p>
      <iframe src="https://logictry.com/apps/63d04aab44c1ba39c4a9f509?autoresize=false"></iframe>
      <h2>Distributor Enablement - Module Selection Tool</h2>
      <p>NI turned a static spreadsheet into this shareable Interactive Content.</p>
      <iframe src="https://logictry.com/apps/611038482fd4cf1d5af35914?autoresize=false"></iframe>
      <h2>Distributor Enablement - EOL Replacement Tools</h2>
      <p>NI turned a static spreadsheet into this shareable Interactive Content.</p>
      <iframe src="https://logictry.com/apps/6116a01e91360b04063deb4e?autoresize=false"></iframe>
      <h2>Partner Enablement - Partner Directory</h2>
      <p>NI turned a static spreadsheet into this shareable Interactive Content.</p>
      <iframe src="https://logictry.com/apps/64bea8a4e8631606caaf3a5d?autoresize=false"></iframe>
    </>
  },
  'rsm-international-technology-maturity-assessments': {
    title: 'RSM International - Technology Maturity Assessments',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/6f44607ef0a478f9d7381aff9890cf49/66abcf3282f9eae19592d14b_rsm.png',
    blog: <>
      <blockquote>"Logictry was instrumental in helping us build and deploy a dynamic, global chatbot application. Their velocity and responsiveness is refreshing!" - Paul&nbsp;Herring, Chief Innovation Officer, RSM&nbsp;International</blockquote>
      <p>RSM International leveraged the Logictry No-Code Interactive Content platform to develop a series of technology maturity assessments, empowering their member firms to make informed technology adoption decisions.</p>
      <p>These assessments were integrated into their member portal, offering valuable resources and training to enhance member capabilities.</p>
      <p>The platform’s flexibility allows for ongoing refinement, enabling RSM International to update and adapt the assessments in response to member feedback and evolving industry trends.</p>
      <h2>Technology Governance</h2>
      <p>Technology strategy development, asset management, IT vendor management and portfolio management.</p>
      <iframe src="https://logictry.com/apps/66d44d6395166bc0e114a183?autoresize=false&autoscroll=false"></iframe>
      <h2>Technology Services</h2>
      <p>Core business process applications; Time recording, management reporting, financial system, CRM, project management, human resource systems, email, calendaring, collaboration tools, websites, client portals, resource planning, training and support, application development and management, device provisioning and management.</p>
      <iframe src="https://logictry.com/apps/66d44e5695166bc0e114a1a0?autoresize=false&autoscroll=false"></iframe>
      <h2>Security Module</h2>
      <p>Asset management, vendor risk management, data loss prevention, security information and event management, single sign-on and MFA, intrusion detection and prevention, data discovery and classification, anti-malware/virus solutions and security training.</p>
      <iframe src="https://logictry.com/apps/66d44e7c95166bc0e114a1ac?autoresize=false&autoscroll=false"></iframe>
      <h2>Technology Operations</h2>
      <p>Outsourcing, shared services, IT team training, recruiting, network access, internet access and cloud adoption.</p>
      <iframe src="https://logictry.com/apps/66d44e9095166bc0e114a1b4?autoresize=false&autoscroll=false"></iframe>
      <h2>Data Governance</h2>
      <p>Data management platforms, data analytics and visualization, data integration, data discovery and classification.</p>
      <iframe src="https://logictry.com/apps/66d44ea095166bc0e114a1bc?autoresize=false&autoscroll=false"></iframe>
    </>
  },
  'the-advocates-political-survey-platform': {
    title: 'The Advocates - Political Survey Platform',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/563fbce1027957568c3a1a0227d804ce/66abcf3282f9eae19592d13b_theadvocates.jpg',
    blog: <>
      <h2>Political Surveys and Quizzes</h2>
      <p>The Advocates, a political organization promoting awareness of a multi-party system, partnered with Logictry's No-Code Interactive Content platform to develop interactive political quizzes and surveys efficiently. The platform's intuitive interface and templates enabled rapid quiz creation, engaging their audience about the diverse political landscape while adapting to specific objectives and demographics.</p>
      <h2>Headless Interactive Content Platform</h2>
      <p>Logictry provided a custom teacher quiz and survey administration portal, simplifying result tracking and management. The user-friendly interface facilitated efficient administration, grading, and analysis of responses, yielding valuable insights into public opinion and political awareness.</p>
      <h2>The World's Smallest Political Quiz</h2>
      <iframe src="https://logictry.com/theadvocates-the-worlds-smallest-political-quiz?autoresize=false&autoscroll=false"></iframe>
      <h2>Government Debt, Money and Monetary Policy</h2>
      <iframe src="https://logictry.com/theadvocates-government-debt-money-and-monetary-policy?autoresize=false&autoscroll=false"></iframe>
      <h2>Custom Interactive Content Portal</h2>
      <p>Logictry's headless No-Code Interactive Content platform allowed for building a custom teacher dashboard for teachers to administer quizzes to their students.</p>
      <iframe src="https://asg.logictry.com/apps/66dae64bc9acf6e18d2c4754?autoresize=false&autoscroll=false&allowredirects=false"></iframe>
      <a href="https://labs.theadvocates.org/"><h3>See it live</h3></a>
    </>
  },
  'onlinemeded-training-directories': {
    title: 'OnlineMedEd - Training Directories',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/f74f47aaa7bc26db0c96e016622e8e05/onlinemeded.webp',
    blog: <>
      <p>OnlineMedEd leveraged the Logictry No-Code Interactive Content platform to build filterable and searchable training content directories.</p>
      <h2>Basic Sciences Training Directory</h2>
      <p></p>
      <iframe src="https://logictry.com/apps/63186d4aca0536075d67a771?autoresize=false&autoscroll=false"></iframe>
      <h2>Clinical Sciences Training Directory</h2>
      <p></p>
      <iframe src="https://logictry.com/apps/63287c6240cd27fed831f096?autoresize=false&autoscroll=false"></iframe>
      <h2>All Lessons Directory</h2>
      <p></p>
      <iframe src="https://logictry.com/apps/64cfc7fa29bc980dbe5a16cf?autoresize=false&autoscroll=false"></iframe>
    </>
  },
  'marketerhire-sales-qualification-tool': {
    title: 'MarketerHire - Sales Qualification Tool',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/b38c697935618375ec7081af82b9cbeb/marketerhire.jpg',
    blog: <>
      <h2>Sales Qualification Tool</h2>
      <p></p>
      <iframe src="https://logictry.com/apps/5f32c99b9594e81fdedf97ca?autoresize=false&autoscroll=false"></iframe>
    </>
  },
  // 'heart-and-flow-puberty-guides': {
  //   title: 'Heart and Flow - Puberty Guides',
  //   image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/38fe5e32ab92e926cd76111f1cb43595/heartandflow.jpg',
  //   blog: <>
      
  //   </>
  // },
  'impact-sales-sales-decision-guides': {
    title: 'Impact Sales - Sales Decision Guides',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/9932f0d8a6a3c09f54e7331506c09d05/impactsales.png',
    blog: <>
      <p>Daniel Moskowitz, a 20+ year experienced CRO, developed a series of Interactive Sales Decision Tools.</p>
      <h2>Evaluating the Potential of 3rd Party Funding to Boost Sales</h2>
      <iframe src="https://logictry.com/apps/66b105d259fcdc76e73b77f6?autoresize=false&autoscroll=false"></iframe>
      <h2>Selecting a Sales CRM</h2>
      <iframe src="https://logictry.com/apps/66aa52a5afb8636dcb8e5945?autoresize=false&autoscroll=false"></iframe>
      <h2>Generational Objection Handling</h2>
      <iframe src="https://logictry.com/apps/66b105c659fcdc76e73b77ee?autoresize=false&autoscroll=false"></iframe>
      <h2>AI Sales Enablement Tools</h2>
      <iframe src="https://logictry.com/apps/66b1053759fcdc76e73b7786?autoresize=false&autoscroll=false"></iframe>
      <h2>Increasing Emotional Triggers in Sales</h2>
      <iframe src="https://logictry.com/apps/66b105cc59fcdc76e73b77f2?autoresize=false&autoscroll=false"></iframe>
      <h2>Sales Call Review</h2>
      <iframe src="https://logictry.com/apps/66b1053d59fcdc76e73b778a?autoresize=false&autoscroll=false"></iframe>
    </>
  },
  'lisabeth-thomas-outwitting-fear-course': {
    title: 'LisaBeth Thomas - Outwitting Fear Course',
    image: 'https://s3.amazonaws.com/logictryimages/5a621bba-29db-4585-9c05-a1c2ece09126_Slide1.JPG',
    blog: <>
      <p>LisaBeth Thomas created a course called "Outwitting Fear," aimed at helping learners identify and overcome their fears.</p>
      <p>The course is hosted on Logictry's No-Code Interactive Content platform allowing for easy content editing and embedding.</p>
      <p>LisaBeth Thomas then used Memberspace to manage the community members and provide access to the content.</p>
      <h2>Outwitting Fear</h2>
      <p></p>
      <iframe src="https://logictry.com/apps/5ebabbfcae527b799ba7f9d6?autoresize=false&autoscroll=false"></iframe>
    </>
  },
  'fyli-community-portal': {
    title: 'Fyli - Community Portal',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/fa43b5b625416f65e3c0c77d0afa47c4/66abcf3282f9eae19592d13a_fyli.jpg',
    blog: <>
      <blockquote>"Logictry has completely changed the way we bring together our community on a digital platform that makes it easy to distribute curriculum, send event details, and share helpful resources. It’s been a pleasure working with the Co-Founders and their innovative team who are always willing to go above and beyond to fit our needs and conceptualize new features together." - Jaclynn Brennan, CEO at FYLI</blockquote>
      <p>Fyli, a female network community, in partnership with Ayana, leveraged the Logictry No-Code Interactive Content platform to build Interactive Resources for their community.</p>
      <p>Fyli also leveraged the fully white label capabilities to deliver a custom login experience for their members.</p>
      <h2>Mastermind Decision Tree</h2>
      <p></p>
      <iframe src="https://logictry.com/fyli-is-a-mastermind-group-right-for-me?autoresize=true&autoscroll=false"></iframe>
      <h2>Investor Directory</h2>
      <p></p>
      <iframe src="https://logictry.com/apps/642ee4383705a00dbdace397?autoresize=false&autoscroll=false"></iframe>
      <h2>Pitch Deck Directory</h2>
      <p></p>
      <iframe src="https://logictry.com/apps/642ee8833705a00dbdace3a0?autoresize=false&autoscroll=false"></iframe>
    </>
  },
  'fiesta-community-resource-directory': {
    title: 'FIESTA - Community Resource Directory',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/10cb56c15c07000cbe9e42f10f1ba966/66abcf3282f9eae19592d145_fiesta.jpg',
    blog: <>
      <p>Fiesta, a dynamic startup community, has joined forces with Logictry's No-Code Interactive Content platform to revolutionize the way founders transition to Austin. With a passion for nurturing innovation, Fiesta harnesses Logictry's capabilities to curate and organize a wealth of resources, simplifying the process of integrating into the vibrant local entrepreneurial scene.</p>
      <p>Through this user-friendly platform, founders gain access to a comprehensive repository of vital information, from co-working spaces and networking events to mentorship opportunities and city insights. The power of Logictry's No-Code Interactive Content platform enables seamless navigation and updates, ensuring that the latest and most relevant resources are readily available.</p>
      <p>As word spreads of Fiesta's exceptional support system, more founders are drawn to Austin, eager to be part of this welcoming community. Logictry's platform has become a catalyst for growth and collaboration, solidifying Fiesta's position as a go-to hub for entrepreneurs seeking a smooth transition and limitless possibilities in the heart of Texas.</p>
      <h2>Austin Accelerators and Incubators Directory</h2>
      <p></p>
      <iframe src="https://logictry.com/fiesta-austin-accelerators-and-incubators?autoresize=false&autoscroll=false"></iframe>
      <h2>Austin Service Providers Directory</h2>
      <p></p>
      <iframe src="https://logictry.com/fiesta-service-providers?autoresize=false&autoscroll=false"></iframe>
      <h2>Austin Free Public Co-Working Spaces Directory</h2>
      <p></p>
      <iframe src="https://logictry.com/fiesta-free-public-co-working-spaces?autoresize=false&autoscroll=false"></iframe>
      <a href="https://www.fiesta.community/resources">See it live</a>
    </>
  },
  'colorado-informed-voting-surveys-and-wildfire-preparation-guides': {
    title: 'Colorado Informed - Voting Surveys and Wildfire Preparation Guides',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/906744a9e2fa4aa8f60301be24a529b3/66abcf3282f9eae19592d144_coloradoinformed.jpg',
    blog: <>
      <p>Colorado Informed utilized Logictry's No-Code Interactive Content platform to tackle vital issues such as wildfire preparedness and voting guidance.</p>
      <p>The voting guide provided personalized instructions to 5,000 voters, improving their access to crucial information.</p>
      <p>The wildfire prevention and preparation guides equip Colorado citizens with the tools to better prepare for and manage wildfires.</p>
      <h2>Colorado Voting Guide</h2>
      <iframe src="https://logictry.com/colorado_informed-how-to-vote-in-colorado?autoresize=true&autoscroll=false"></iframe>
      <h2>Wildfire Prevention Guide</h2>
      <iframe src="https://logictry.com/colorado_informed-wildfire-prevention--safety-before-during--after?autoresize=true&autoscroll=false"></iframe>
      <h2>Wildfire Preparation Guide</h2>
      <iframe src="https://logictry.com/apps/63f64b4d5e200055323bb325?autoresize=false&autoscroll=false"></iframe>
      <a href="https://coloradoinformed.com/survey/">See it live</a>
    </>
  },
  'the-acacia-company-dei-assessments': {
    title: 'The Acacia Company - DEI Assessments',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/ac4a78a8d15e86096a3a1367df6218e5/66abcf3282f9eae19592d146_acacia.jpg',
    blog: <>
      <p>The Acacia Company utilized Logictry's No-Code Interactive Content platform to create a branded DEI assessment that seamlessly incorporated their complex logic requirements.</p>
      <blockquote>"My team and I struggled to find a tool that could handle all of the results possibilities of our assessment and have a nice looking and simple front end at the same time." - Natalie Rodger, CEO at Acacia</blockquote>
      <p>The simple No-Code editor allows The Acacia Company to make edits quickly and easily.</p>
      <blockquote>"Logictry is great for us because it’s as friendly to use as forms, and as smart as excel." - Natalie Rodger, CEO at Acacia</blockquote>
      <h2>DEI Assessment</h2>
      <p></p>
      <iframe src="https://logictry.com/theacaciacompany-dei-business-assessment?autoresize=false&autoscroll=false"></iframe>
      <a href="https://www.theacaciacompany.com/assessment">See it live</a>
    </>
  },
  'within-performance-branding-assessment': {
    title: 'Within - Performance Branding Assessment',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/65015bd498ba44c7846828a028edf29b/Screenshot%202024-08-08%20at%209.44.49%E2%80%AFPM.png',
    blog: <>
      <p>Within leveraged the Logictry No-Code Interactive Content platform to build a fully custom branded Assessment.</p>
      <p>Logictry's platform was able to uniquely build their custom PDF report exactly to design specifications required.</p>
      <p>No other platform on the market offers the same level of customization.</p>
      <p><b>Try it now!</b></p>
      <iframe src="https://logictry.com/apps/60f0760040cc482d057c6ade?autoresize=false&autoscroll=false"></iframe>
    </>
  },
  'strategy-dna-climate-readiness-assessment': {
    title: 'Strategy DNA - Climate Readiness Assessment',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/1ff044c5e988e7c55231609bcfaa5334/strategydna.png',
    blog: <>
      <blockquote>“Logictry has been an important tool in scaling our practice – from deploying custom assessments to creating e-learning modules and helping automate internal processes. The ability to custom-build exactly what we need is simply priceless.” - Valentina Fomenko, CEO, Strategy DNA</blockquote>
      <p>Strategy DNA Consulting used the Logictry No-Code Interactive Content platform to create a Climate Readiness Assessment that empowers business leaders to evaluate their organization's climate preparedness.</p>
      <p>This tool not only provides valuable insights but also serves as a lead generation tool, prompting leaders to engage Strategy DNA Consulting for expert guidance and tailored, sustainable solutions.</p>
      <iframe src="https://logictry.com/strategydna-climate-readiness-assessment?autoresize=true&autoscroll=false"></iframe>
    </>
  },
  'conversion-sciences-roi-calculator': {
    title: 'Conversion Sciences - ROI Calculator',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/c7044e767cd8df53e3eb07def4ca1b61/conversionsciences.jpeg',
    blog: <>
      <p>Conversion Sciences, a data-driven conversion optimization firm, partnered with Logictry to build interactive ROI calculators using their no-code platform. The calculators allowed potential clients to input their site metrics and instantly see how Conversion Sciences' services could increase their conversions and revenue.</p>
      <p>By visualizing the potential ROI, the calculators empowered prospects to make data-backed decisions, reinforcing Conversion Sciences' message of scientifically tested solutions. The result was an engaging tool that increased lead generation and showcased the measurable value of their services.</p>
      <iframe src="https://logictry.com/apps/60f1dc8b40cc482d057c6ae3?autoresize=false&autoscroll=false"></iframe>
    </>
  },
  'paladin-auditing-bluebook': {
    title: 'Paladin - Auditing Bluebook',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/136c3a6e3b7c148802b3ce4257855b69/66abcf3282f9eae19592d142_paladin.jpg',
    blog: <>
      <blockquote>"I've been searching for this product for 2 years! We needed a system that could capture and standardize our complex proprietary processes." - Aaron Silva, CEO of Paladin</blockquote>
      <p>Paladin, a renowned auditing firm, has leveraged the Logictry No-Code Interactive Content platform to systematize their proprietary auditing process, ensuring consistency and efficiency across all audits.</p>
      <p>By centralizing their knowledge management, the platform accelerates new hire onboarding, preserves critical expertise, and enhances operational accuracy, solidifying Paladin's reputation as a trusted auditing firm.</p>
      <h2>No demo available due to confidential information.</h2>
    </>
  },
  'peloton-u-smart-faq': {
    title: 'Peloton U - Smart FAQ',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/6ddd11cae7f79fb5c04caad3b9907539/pelotonu.jpg',
    blog: <>
      <p>PelotonU, an online education organization, leveraged the power of the Logictry No-Code Interactive Content platform to design dynamic and intelligent FAQs that offer personalized and relevant assistance to learners.</p>
      <p>The platform's user-friendly interface and drag-and-drop functionality made it easy for PelotonU to create and update the FAQs, ensuring that the information remains accurate and up to date.</p>
      <h2>PelotonU - Smart FAQ</h2>
      <iframe src="https://logictry.com/apps/618051d4ad08570fc96596e9?autoresize=true&autoscroll=false"></iframe>
    </>
  },
  'athleta-standard-operating-procedure': {
    title: 'Athleta - Standard Operating Procedures',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/31374ba0d7f6180d38f28d307f77c1ee/athleta.png',
    blog: <>
      <p>Previously, event planners at Athleta struggled with a 500-page onboarding document, often skipping crucial sections or failing to fully grasp the content, resulting in poor event execution and high turnover.</p>
      <p>By partnering with Logictry to create an interactive standard operating procedure (SOP) using Logictry's No-Code Interactive Content platform, Athleta has resolved these issues, ensuring planners are now fully engaged, better prepared, and able to execute successful events with confidence.</p>
      <p>With this innovation, any new hire can now plan events with the expertise of a top event planner instantly, drastically reducing turnover and improving event outcomes from the start.</p>
      <h2>Event Planner Standard Operating Procedure</h2>
      <iframe src="https://logictry.com/apps/5e841cb875b8a92a15455b0f?autoresize=true&autoscroll=false"></iframe>
    </>
  }
}
export default caseStudies;
