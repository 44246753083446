import React from "react";
import styled from "styled-components";
import Navbar from "../../components/Navbar";
import Header from "../../styledhtml/Header";
import Main from "../../styledhtml/Main";
import Section from "../../styledhtml/Section";
import Footer from "../../components/Footer";
import { device } from "../../submodules/logictry_common/src/utils/device";
import Content from "../../components/Content";
import { Helmet } from 'react-helmet';

const CompanyHeader = styled(Header)`
  flex-direction: column;
  max-width: 1200px;
  align-items: center;
  text-align: center;
  margin: auto;
  @media ${device.laptop} {
    margin-top: 2rem;
    gap: 2rem;
  }
`;
const Team = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  > a {
    text-decoration: none;
    flex: 0 0 calc((100% - 6rem) / 4);
    @media ${device.desktop} {
      flex: 0 0 calc((100% - 4rem) / 3);
    }
    @media ${device.laptop} {
      flex: 0 0 calc((100% - 2rem) / 2);
    }
    @media ${device.tablet} {
      flex: 0 0 calc(100%);
    }
    > div {
      text-align: center;
      > img {
        border-radius: 1rem;
      }
      h3 {
        margin: 1rem 0 0;
        font-weight: 600;
      }
      p {
        margin: 0rem 0 0;
      }
    }
  }
`;
const Values = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  > div {
    width: calc(50% - 1rem);
    @media ${device.tablet} {
      width: 100%;
    }
    position: relative;
    img {
      filter: brightness(0.5);
      border-radius: 1rem;
    }
    > div {
      position: absolute;
      inset: calc(50% - 25px) 0;
      color: white;
      text-align: center;
      line-height: 50px;
      font-size: 2rem;
    }
  }
`;
const TEAM = [
  {
    name: 'Chris Fronda',
    title: 'Chief Logic Officer',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/7177016ee5b75d5d6f7b296f9544093f/chrisfronda.jpeg',
    linkedIn: 'https://www.linkedin.com/in/chrisfronda/'
  },
  {
    name: 'Chelsea Toler',
    title: 'Chief Impact Officer',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/8a07d3143775ee20e9a7011b15cbf079/chelseatoler.jpeg',
    linkedIn: 'https://www.linkedin.com/in/catoler92/'
  },
  {
    name: 'Nisaa Jetha',
    title: 'Head of Global Strategy',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/69ad3cb722fb5314cced040c21615b87/Screenshot%202024-08-30%20at%209.56.17%E2%80%AFAM.png',
    linkedIn: 'https://www.linkedin.com/in/nisaajetha/'
  },
  {
    name: 'Alex Trevino',
    title: 'Head of Solutions',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/36c95f48e7e3789cb07e6fa2fa888e7e/alextrevino.jpeg',
    linkedIn: 'https://www.linkedin.com/in/alex-trevino/'
  },
  {
    name: 'Paris Tingley',
    title: 'Head of Sales',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/7dccf29acc51640a857289ad1342274b/paristingley.jpeg',
    linkedIn: 'https://www.linkedin.com/in/paris-tingley-10b147101/'
  },
  {
    name: 'John Zozzaro',
    title: 'Head of Marketing',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/a3448621fa16fa18b74a7af89d7b918a/johnzozzaro.jpg',
    linkedIn: 'https://www.linkedin.com/in/johnzozzaro/'
  },
  {
    name: 'Christine Prydatko',
    title: 'Head of Media',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/f8f3fbdc5ebefd74124af19069972a11/christineprydatko.jpg',
    linkedIn: 'https://www.linkedin.com/in/christine-prydatko-95991383/'
  },
  // {
  //   name: 'Eric Tucker',
  //   title: 'Head of Product',
  //   image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/51f292937b2eecf3a0df36d4eb3fed41/erictucker.png',
  //   linkedIn: 'https://www.linkedin.com/in/erictucker/'
  // },
  // {
  //   name: 'Steve Ellis',
  //   title: 'Head of Revenue',
  //   image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/45eeb95abd61fd60f7d3dc0eb15b0d45/steveellis.jpeg',
  //   linkedIn: 'https://www.linkedin.com/in/thesteveellis/'
  // },
  {
    name: 'Jake Glascock',
    title: 'Head of Engineering',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/d47737c95cf39a63f45831428b58d752/Screenshot%202024-08-30%20at%209.41.33%E2%80%AFAM.png',
    linkedIn: 'https://www.linkedin.com/in/jakeglascock/'
  },
  {
    name: 'Nathan Tacha',
    title: 'Head of Trust and Safety',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/4ec5f4fde048f78a5afc50061497af6e/1516315038058.jpeg',
    linkedIn: 'https://www.linkedin.com/in/nathantacha/'
  },
];
const VALUES = {
  innovation: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/d5a83f62c23998bd86f4cc554c3d16c6/innovation.jpeg',
  impact: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/28c405643ee5f586f60d348978edb83c/impact.jpeg',
  curiosity: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/42e160ec2e153bf6cef7ad52e0894928/curiosity.jpg',
  collaboration: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/b685552f2308a7d762597600e428b61c/collaboration.jpg',
  learning: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/fdd59114e931f42caf14ac46b19d9efa/learning.jpg',
  logic: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/1621c193648758801e75e2faa19347f8/logic.jpg'
}

const Company = () => {
  return (
    <>
      <Helmet>
        <title>About Logictry</title>
      </Helmet>
      <Navbar />
      <CompanyHeader>
        <div>
          <h1>About <span>Logictry</span></h1>
          <p>We help keep humans and human intelligence thriving in this new era of artificial intelligence.</p>
        </div>
        {Content({ image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/97705186d4f1733879a9a012cace05d1/394583155_18395589796016950_3826289400259936789_n.jpg' }, true)}
      </CompanyHeader>
      <Main>
        {/* <SectionHorizontal>
          <div>
            <h2>Our News</h2>
          </div>
          {Content({ image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/edf99f7e6cd5c8f1975d7d4cb52c7cf6/c-1717361070219-chelsea_toler_kxan-photo.jpg' }, true)}
        </SectionHorizontal> */}
        <Section style={{ maxWidth: 1200, margin: '2rem auto', textAlign: 'center', alignItems: 'center' }}>
          <div>
            <h2>Our Mission</h2>
            <p>Scale the world's wisdom and empower everyone to make better decisions faster.</p>
            <h2>Our Vision</h2>
            <p>Democratize expertise to unlock human potential and make logic possible.</p>
            <h2>Our Values</h2>
          </div>
          <Values>
            {Object.keys(VALUES).map((value) => (
              <div key={value}>
                <img alt={VALUES[value]} src={VALUES[value]}></img>
                <div>{value}</div>
              </div>
            ))}
          </Values>
        </Section>
        {/* <Section style={{ maxWidth: 1200, margin: '2rem auto', alignItems: 'center' }}>
          <div>
            <h2>Our Team</h2>
          </div>
          <Team>
            {TEAM.map(({ name, title, image, linkedIn }) => (
              <a href={linkedIn} target="_blank" rel="noopener noreferrer" key={name}>
                <div>
                  <img alt={name} src={image}></img>
                  <h3>{name}</h3>
                  <p>{title}</p>
                </div>
              </a>
            ))}
          </Team>
        </Section> */}
      </Main>
      <Footer />
    </>
  )
};
export default Company;
