// Import all the third party stuff
import React from "react";
import ReactDOM from "react-dom";

// Import root app
import App from "./applications/App";
import "./css/custom.css";

const MOUNT_NODE = document.getElementById("app");
const render = () => {
  ReactDOM.render(<App />, MOUNT_NODE);
};

render();
