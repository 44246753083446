import React, { useContext } from "react";
import { isDevelopment } from "../../utils/getEnvironment";
import useAppConfig from "../../hooks/server/useAppConfig";
import useAppAccount from "../../hooks/server/useAppAccount";

const ConfigContext = React.createContext({});

interface ConfigProviderProps {
  children: React.ReactChild;
  defaultConfig: any;
}

const ConfigProvider = ({ children, defaultConfig }: ConfigProviderProps) => {
  const { config, setConfig } = useAppConfig();
  if (isDevelopment && !config) {
    setConfig(defaultConfig);
  }
  const { account, setAccount, setLogin, setLogout, setPatchImage, setRegister, setResetPassword, setVerifyAccount, setChangePassword, setUpdateAccount } = useAppAccount(isDevelopment, config);
  const value = { config, setConfig, account, setAccount, setLogin, setLogout, setPatchImage, setRegister, setResetPassword, setVerifyAccount, setChangePassword, setUpdateAccount };
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export type MyContext = {
  config: any;
  setConfig: any;
  account: any;
  setAccount: any;
  setLogin: any;
  setLogout: any;
  setPatchImage: any;
  setRegister: any;
  setResetPassword: any;
  setVerifyAccount: any;
  setChangePassword: any;
  setUpdateAccount: any;
};

/* Hook for accessing Config context */
// Use as: "const {config, setConfig} = useConfig();"
const useConfig = () => {
  const context = useContext(ConfigContext) as MyContext;
  if (context === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};

export { ConfigProvider, useConfig };
