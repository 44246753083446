import React from "react";
import Content from "../../components/Posts";
import caseStudies from "./casestudies";

const CaseStudies = () => {
  return (
    <Content TITLE={"Case Studies"} DESCRIPTION="Discover detailed case studies showcasing our successful projects and client solutions." CONTENT={caseStudies} />
  )
};
export default CaseStudies;
