/* eslint-disable eqeqeq */
/* eslint-disable no-bitwise */
import { useState } from "react";
import { isEmbedded } from "../../utils/getEnvironment";

function useAppConfig() {
  const [config, setConfig] = useState(null);
  if (config) return { config, setConfig };

  const payload = { request: { action: "GetConfig" } };

  // Define handler function for receiving config response from window.parent
  function __handleMessage(evt) {
    let data;
    try {
      data = JSON.parse(evt.data);
    } catch (e) {
      // UNCAUGHT ERROR
    }
    if (!data || !data.request || !data.response) return;

    if (data.request.action === "GetConfig") {
      // Save config to context
      const config = data.response.body;
      // if (config && config.tables) {
      //   denormalizeConfig(config);
      // }
      setConfig(config);

      // Remove event listener
      window.removeEventListener("message", __handleMessage, false);
    }
  }

  // (TO DO: Need both event attachers?)
  if (window.addEventListener) {
    window.addEventListener("message", __handleMessage, false);
  } else {
    window.attachEvent("onmessage", __handleMessage);
  }

  if (isEmbedded) {
    window.parent.postMessage(JSON.stringify(payload), "*");
  }

  return { config, setConfig };
}

// // Can probably delete this function, not being used
// export function denormalizeConfig(config) {
//   config.tables.forEach((table) => {
//     table.columns.forEach((column) => {
//       if (column.collection)
//         column.collection = config.collections[column.collection];
//       if (column.choices)
//         column.choices = column.choices.map((choice) => ({
//           name: choice,
//           value: choice,
//         }));
//     });
//   });
// }

export default useAppConfig;
