import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";

const FullScreenApp = ({ title, app }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <header style={{ margin: '12rem auto 0', textAlign: 'center' }}>
        <h1>{title}</h1>
      </header>
      <Navbar />
      <main style={{ width: '100%', maxWidth: 1200, margin: '4rem auto' }}>
        <iframe style={{ width: '100%' }} src={`https://app.logictry.com/apps/${app}?allowfullscreen=false&autoscroll=false`}></iframe>
      </main>
    </>
  )
};
export default FullScreenApp;
