import { isEmbedded } from "../../utils/getEnvironment";

/* eslint-disable no-param-reassign */
const __observers = {};

const cachedResponses = {};

/* Function to build pathString for use in caching */
const buildPathString = ({ method, path, body }) =>
  JSON.stringify([method, path, body]);

/* Functions to combine path and query string parameters into a single string */
function __formatQueryParameters(params) {
  let result = [];
  params.forEach(({ key: k, value: v, op: o = "e" }) => {
    // (If no value, don't include this parameter in request)
    if (!v) {
      return;
    }

    let s = "";

    // Encode value
    v = encodeURI(v);

    // Configure param string based on op
    switch (o) {
      case "e":
        s = `${k}=${v}`;
        break;
      case "false":
      case "true":
      case "lte":
      case "gte":
      case "ne":
      case "regex":
        s = `${k}[${o}]=${v}`;
        break;
      case "asc":
        s = `${k}=+${v}`;
        break;
      case "desc":
        s = `${k}=-${v}`;
        break;
      default:
        // UNCAUGHT ERROR
        break;
    }

    // Add to result
    result.push(s);
  });

  result = result.join("&");
  return result;
}
function __formatPath(_path, _query) {
  // let url = `https://logictry.com${_path}`;
  let url = `${_path}`;

  if (_query && _query.length > 0)
    url = `${url}?${__formatQueryParameters(_query)}`;

  return url;
}

export function addEventListener(pathString, uuid, cb) {
  // Check if the pathString already has a pending request
  if (Object.keys(__observers).includes(pathString)) {
    __observers[pathString].push({
      uuid,
      cb,
    });
  } else {
    __observers[pathString] = [
      {
        uuid,
        cb,
      },
    ];
  }
}
/* Function for removing event listeners for when React component unmounts before response is returned */
export function removeEventListeners(uuids) {
  Object.entries(__observers).forEach(([pathString, events]) => {
    Object.values(events).forEach(({ uuid, cb }) => {
      if (uuids.includes(uuid)) {
        // (If there is only one event, delete the entire pathString from __observers)
        // (Otherwise, only remove the single event)
        if (Object.values(events).length === 1) {
          delete __observers[pathString];
        } else {
          __observers[pathString] = events.filter((e) => e.uuid !== uuid);
        }
      }
    });
  });
}

function __emitStateUpdate(_update) {
  /* Save the response into the cache */
  // Save response
  if (!_update || !_update.request) return;
  const pathString = buildPathString(_update.request);
  cachedResponses[pathString] = _update.response;

  // Set timeout to delete
  setTimeout(() => {
    delete cachedResponses[pathString];
  }, 1000);

  // Find the UUID within the __observers' events
  Object.entries(__observers).forEach(([pathString, events]) => {
    Object.values(events).forEach(({ uuid, cb }) => {
      if (uuid === _update.uuid) {
        // Execute callback for each event in the same pathString as the given UUID
        Object.values(__observers[pathString]).forEach(({ uuid, cb }) => {
          cb(_update.response);
        });
      }
    });
  });

  // (TO DO: Add try/catch here in case the listener has already been removed when it tries to fire)
}

function __handleMessage(evt) {
  let data;
  try {
    data = JSON.parse(evt.data);
  } catch (e) {
    // UNCAUGHT ERROR
  }
  if (!data) return;

  __emitStateUpdate(data);
}

export function apiCall(uuid, cb, method, path, query, body) {
  /* Combine path and query parameters */
  const pathAndQuery = __formatPath(path, query);

  // Build "pathString"
  const pathString = buildPathString({
    method,
    path: pathAndQuery,
    body,
  });

  // If we already have a cached response for the pathString, just return the response to the callback
  if (Object.keys(cachedResponses).includes(pathString)) {
    return cb(cachedResponses[pathString]);
  }

  // (Add the event listener, but first check if a request to this pathString is already pending)
  const alreadyPending = Object.keys(__observers).includes(pathString);
  addEventListener(pathString, uuid, cb);

  /* Make the request if there is no pending request to this pathString */
  if (!alreadyPending) {
    __apiCall({
      uuid,
      method,
      path: pathAndQuery,
      body,
    });
  }
}

function __apiCall({ uuid, method, path, body }) {
  if (isEmbedded) {
    // Infer if we are in production or development environment
    /* Build payload */
    const payload = {
      uuid,
      request: {
        action: "NetworkRequest",
        method,
        path,
        body,
      },
    };

    window.parent.postMessage(JSON.stringify(payload), "*");
  } else {
    const URL = `${window.location.origin}${path}`;
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === XMLHttpRequest.DONE) {
        /* Format response object */
        const { status } = xhttp;
        const response = {
          uuid,
          request: { method, path, body },
          response: {
            body: JSON.parse(xhttp.responseText || "{}"),
            status,
          },
        };

        // Emit the response
        __emitStateUpdate(response);
      }
    };
    xhttp.open(method, URL, true);
    xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    const csrfToken = document.querySelector('meta[name="csrf-token"]');
    if (csrfToken) xhttp.setRequestHeader("csrf-token", csrfToken.content);
    xhttp.send(JSON.stringify(body || {}));
  }
}

/* Attach "message" event listener to window */
// (TO DO: Need to only do this if in production environment?)
if (window.addEventListener) {
  window.addEventListener("message", __handleMessage, false);
} else {
  window.attachEvent("onmessage", __handleMessage);
}
