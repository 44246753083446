import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Document Generators"
  },
  "header": {
    "title": <><span>Interactive</span> Document Generators</>,
    "description": "Create legal and other documents efficiently with AI-Enabled document generators.",
    "app": "/ycombinator-safe-document-generator"
  },
  "main": [
    {
      "title": "FAST Agreement",
      "description": "Generate FAST agreements with ease using our document generator, modeled after the Founder Institute's template.",
      "app": "/thefounderinstitute-fast-agreement-document-generator?collection=legal-templates"
    },
    {
      "title": "Ease Agreement",
      "description": "Create Ease agreements effortlessly with our document generator, inspired by the Founder Institute's EASE template.",
      "app": "/thefounderinstitute-ease-agreement-document-generator"
    },
    {
      "title": "Mutual Non-Disclosure",
      "description": "Produce Mutual Non-Disclosure Agreements quickly with our generator, based on the CooleyGo NDA template.",
      "app": "/cooleyllp-mutual-non-disclosure-agreement?collection=legal-templates"
    }
  ]
}

const DocumentGenerators = () => {
  return (
    <Template content={content} />
  )
};
export default DocumentGenerators;
