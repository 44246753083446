import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Guided Selling Tools",
  },
  "header": {
    "title": <>Interactive <span>Guided</span> Selling for Faster Sales Velocity</>,
    "description": "We turn a complex sales process into an easy button.",
    "youtube": "https://www.youtube.com/embed/941kI-S6ZhE"
  },
  "main": [
    {
      "title": "The Future of Sales",
      "description": <>Watch a quick <b>5 min video</b> on the different types of guided selling tools you can build.</>,
      "loom": "https://www.loom.com/embed/a2784a834b494e0187bf7affa98e4b4c?sid=e607b216-59b5-4d49-a315-dd425e1b9bf6"
    },
    // {
    //   "title": "ROI Calculator",
    //   "description": "Discover the revenue generation and cost savings potential of Logictry for your organization.",
    //   "app": "/apps/6097e0e05d8c1145de54ec3a"
    // },
  ],
}

const SupportTeams = () => {
  return (
    <Template content={content} />
  )
};
export default SupportTeams;
