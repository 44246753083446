import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Better Client Onboarding",
  },
  "header": {
    "title": <>Better Client Onboarding</>,
    "description": "Powerful interactive intake forms that better qualify and educate clients before meeting.",
    "app": "/apps/64d19b1c29bc980dbe5a179f"
  },
  "main": [
    {
      "title": "Decision Tree Example",
      "description": "The original static content and the converted interactive content",
      "app": "/thefounderinstitute-fast-agreement-document-generator?collection=legal-templates"
    },
    {
      "title": "Decision Tree Example",
      "description": "The original static content and the converted interactive content",
      "app": "/cooleyllp-mutual-non-disclosure-agreement?collection=legal-templates"
    },
  ],
}

const LawFirms = () => {
  return (
    <Template content={content} />
  )
};
export default LawFirms;
