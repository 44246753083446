import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Sales Configurators",
  },
  "header": {
    "title": <><span>Interactive</span> Sales Configurators</>,
    "description": "Accelerate sales velocity and improve the accuracy of product recommendations.",
    "youtube": "https://www.youtube.com/embed/941kI-S6ZhE"
  },
  "main": [
    {
      "title": <>Interactive <span>AI-Enabled</span> Sales Configurators</>,
      "description": "DEMO: Try typing the words: Data Logging 8106",
      "app": "/apps/5e41733f18397e3927db88ee"
    },
    {
      "title": "Interactive Sales Playbooks",
      "description": <><span>93%</span> of marketers agreed that interactive content is more successful at educating buyers.</>,
      "loom": "https://www.loom.com/embed/a2784a834b494e0187bf7affa98e4b4c?sid=e607b216-59b5-4d49-a315-dd425e1b9bf6"
    },
  ],
  "caseStudies": [
    "national-instruments-sales-distributor-and-partner-enablement",
    "marketerhire-sales-qualification-tool"
  ]
}

const SalesConfigurators = () => {
  return (
    <Template content={content} />
  )
};
export default SalesConfigurators;
