/*
This file is the starting place for the app configuration.

It is passed through to the common library's "useConfig",
then pulled into local "useConfig" to match up the type definition
for use throughout the app.
*/

interface BrandConfig {
  name: string;
  color: string;
}
interface CollectionConfig {
  _id: string;
}
type FieldType =
  | "text"
  | "textarea"
  | "number"
  | "select"
  | "radio"
  | "checkbox"
  | "slider"
  | "date"
  | "datetime-local";
export interface ModelProperty {
  title: string;
  key?: string;
  fieldType: FieldType;
  required?: boolean;
  link?: { prefix: string; key: string };
  collection?: string;
  boundary?: any;
  nested?: { key: string };
  filteredBy?: { [key: string]: { key: string } };
  choices?: { name: string; value: string | number | boolean }[];
  searchable?: boolean;
  sortable?: boolean;
  width?: number;
  account?: boolean;
  formula?: any;
}
export interface TableConfig {
  title: string;
  key: string;
  filters: { key: string; value: string; op?: string }[];
  columns: ModelProperty[];
}
interface ProfileTableConfig {
  title: string;
}
interface ModelProfileConfig {
  title: string;
  key: string;
  /** Specifies which fields to auto-fill when creating a new model instance */
  create?: { params: string[] };
  tables: ProfileTableConfig[];
}
export interface ModelConfig {
  name: string;
  collection: CollectionConfig;
  table: TableConfig;
  profile: ModelProfileConfig;
}
export interface AppConfig {
  brand: BrandConfig;
  models: ModelConfig[];
}

const DEFAULT_CONFIG: AppConfig = {
  brand: {
    name: "ASG",
    color: "#06a8fc",
  },
  models: [
  ],
};

export default DEFAULT_CONFIG;