import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Section from '../../styledhtml/Section';
import { device } from "../../submodules/logictry_common/src/utils/device";

const Wrapper = styled(Section)`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  max-width: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 4rem 0rem;
  @media ${device.laptop} {
    padding: 2rem 0rem;
  }
  h2 {
    text-align: center;
  }

  .scroll-container {
    display: flex;
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    .testimonial-wrapper {
      display: inline-flex;
    }
    .title {
      font-size: 0.75rem;
      margin: 0;
    }

    .testimonial {
      
      padding: 2rem 3rem;
      margin: 0 1rem 2rem;
      background: white;
      width: 480px;
      border-radius: 1rem;
      box-shadow: 17px 17px 20px #40454f0f;

      img {
        border-radius: 50%;
        margin-right: 15px;
        width: 50px;
        height: 50px;
      }

      > div {
        text-align: left;
        display: flex;
      }
      > p {
        margin: 1rem 0 0;
        white-space: break-spaces;
        font-size: 1rem;
      }

      h4 {
        margin: 0;
      }
    }
  }
`;

const testimonials = [
  {
    name: 'Aaron Silva',
    title: 'CEO, Paladin',
    feedback: `I've been searching for this product for 2 years! We needed a system that could capture and standardize our complex proprietary processes.`,
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/8e3fb3fc9014879c8e62ceb1f80d151c/aaronsilva.jpeg',
  },
  {
    name: 'Paul Herring',
    title: 'CINO, RSM International',
    feedback: `I selected Logictry to build a capability maturity model and self-service assessment interface for national technology leaders. The Logitry team did an outstanding job in rapidly designing, building and deploying an effective solution for us.`,
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/0cb0acaa79ac6d55073a9eceffbdb77a/paulherring.jpeg',
  },
  {
    name: 'David Altounian',
    title: 'Vice Provost, Graduate and Professional Studies',
    feedback: `Just as Tableau has pioneered powerful, user-friendly tools for data visualization of complex data, Logictry is pioneering powerful, user-friendly tools for data manipulation of complex data sets to allow users to quickly build useful end-user solutions.`,
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/fc1fc6844be053d407db2e39e776eb07/davidaltounian.jpeg',
  },
  {
    name: 'Will King',
    title: 'VP, Salient Systems',
    feedback: `SalesForce is a compass, Logictry is a map.`,
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/385c3f79bbdfadfe3aed2fbeef827560/willking.jpeg',
  },
  {
    name: 'Patricia Friar',
    title: 'CMO, Capital Factory Mentor',
    feedback: 'Logictry has some very unique technology that helps customers accelerate their digital transformation process both in managing unstructured data relationships, rapid UI development and services.',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/aae14243e9a510b3771093ec6445d6b8/patriciafriar.jpeg',
  },
  {
    name: 'Mike Chudy',
    title: 'Founder, Austin Sales Consulting',
    feedback: 'Logictry provides solutions to the business problems that never seem to get solved.',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/d3569194192e0a10a88325a3bbe3c20b/mikechudy.jpeg',
  },
  {
    name: 'Penny Merian',
    title: 'Chief Product Marketer, NI',
    feedback: 'Catching all the interdependencies is what makes the logic in Logictry powerful.',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/61104ac97cd8ad0da669ba38043c2e5c/pennymerian.jpeg',
  },
  {
    name: 'Jake Glascock',
    title: 'Engineering Manager, Procore',
    feedback: 'Logictry is the Segment for KnowledgeBase systems, creating a search across all platforms.',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/d7efa07c6dc86ddf753c5bcfb7a5aca9/jakeglascock.jpeg',
  },
  {
    name: 'Valentina Fomenko',
    title: 'CEO, Strategy DNA',
    feedback: 'Logictry has been an important tool in scaling our practice – from deploying custom assessments to creating e-learning modules and helping automate internal processes. The ability to custom-build exactly what we need is simply priceless.',
    image: 'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/590fa838339a1aad42be32be0be24168/valentinafomenko.jpeg',
  }
];

const Testimonials = () => {
  const scrollContainerRef = useRef(null);
  const speed = 1 / 2; // pixels per frame

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let scrollAmount = 0;

    const scrollContent = () => {
      scrollAmount += speed;
      if (scrollAmount >= scrollContainer.scrollWidth / 2) {
        scrollAmount = 0;
      }
      scrollContainer.scrollLeft = scrollAmount;
      requestAnimationFrame(scrollContent);
    };

    requestAnimationFrame(scrollContent);
  }, []);

  return (
    <Wrapper>
      <div>
        <h2>What Our Clients Say</h2>
      </div>
      <div className="scroll-container" ref={scrollContainerRef}>
        <div className="testimonial-wrapper">
          {testimonials.concat(testimonials).map((testimonial, index) => (
            <div className="testimonial" key={index}>
              <div>
                <img src={testimonial.image} alt={testimonial.name} />
                <div>
                  <h4>{testimonial.name}</h4>
                  <p className="title">{testimonial.title}</p>
                </div>
              </div>
              <p>"{testimonial.feedback}"</p>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default Testimonials;
