import styled from 'styled-components';
import { device } from "../../submodules/logictry_common/src/utils/device";
import Constants from '../../submodules/logictry_config/constants';

export default styled.section`
  padding: 4rem 2rem;
  margin: 2rem auto;
  max-width: 1440px;
  gap: 4rem;
  @media ${device.laptopL} {
    padding: 2rem 2rem;
    margin: 2rem auto;
    gap: 2rem;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > div:first-child {
    max-width: 28rem;
    h2 {
      margin-bottom: 1rem;
      font-size: 2rem;
      @media ${device.tablet} {
        font-size: 2.4rem;
      }
      @media ${device.mobileL} {
        font-size: 1.8rem;
      }
      font-weight: 500;
      span {
        background-color: ${Constants.PrimaryColor};
        color: white;
        padding: 0.25rem 0.5rem;
        margin: -0.25rem 0rem;
        border-radius: 0.5rem;
      }
    }
    p {
      color: #505256;
      font-size: 1.3rem;
      @media ${device.tablet} {
        font-size: 1.2rem;
      }
      @media ${device.mobileL} {
        font-size: 1.1rem;
      }
      span {
        background-color: #ff5f00;
        color: white;
        padding: 0.25rem 0.5rem;
        margin: -0.25rem 0rem;
        border-radius: 0.5rem;
        font-size: 1.6rem !Important;
      }
    }
    flex: 2;
    > a:last-child {
      > button {
        color: ${Constants.DarkGray};
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
        font-size: 1.1rem;
        font-weight: 600;
      }
    }
  }
  > div:last-child {
    flex: 3;
    width: 100%;
  }
  iframe {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%;
  }
`;
