import { useState, useEffect } from "react";
import { QueryParam } from "../../components/Table";
import {
  CollectionString,
  ModelInstance,
  NestedObject,
} from "../../types/server/ApiTypes";
import useApiCall from "./useApiCall";
import concatenateKeys from '../../utils/concatenateKeys';

export default function useAppQuery(
  url: any,
  queryParams: QueryParam[],
  nestedObjects: NestedObject[]
) {
  const path = `/v1/${url}`;
  const API = useApiCall();
  const [query, setQuery] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [reachedEnd, setReachedEnd] = useState<boolean>(false);

  const dependencyString = JSON.stringify([
    url,
    queryParams,
    nestedObjects,
  ]);

  // (If request parameters change, reset the query)
  useEffect(() => {
    setQuery(null);
    setReachedEnd(false);
    setLoading(true);
  }, [dependencyString]);

  useEffect(() => {
    const retrieveItems = () => {
      // (Check that we haven't already retrieved all available results)
      if (!reachedEnd && queryParams && queryParams.length > 0) {
        /* Make call to API */
        API(
          (response: any) => {
            const { body } = response;
            if (
              body.length !==
              queryParams.find(({ key }) => key === "limit")?.value
            ) {
              setReachedEnd(true);
            }
            setQuery(body);
            setLoading(false);
          },
          "GET",
          path,
          queryParams
        );
      } else {
        setLoading(false);
      }
    };
    retrieveItems();
  }, [loading]);

  return { loading, setLoading, reachedEnd, setReachedEnd, query, setQuery };
}
