import React from "react";
import Template from "../../components/Template";
import Sitemap from '../../components/Navbar/sitemap.json';

const content = {
  "meta": {
    "title": "No-Code Interactive Content Platform",
  },
  "header": {
    "title": <><span>No-Code</span> Interactive Content Platform</>,
    "description": "At Logictry, we are on a mission to scale expertise and unlock better decisions in your personal life, in your professional life, and in the world.",
    "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/26370f3083e00338fddf35056235cbf0/Screenshot%202024-02-02%20at%204.10.33%E2%80%AFPM.png",
  },
  "main": [
    {
      "title": <>Simple No-Code Editor</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/40a4c4eff8ece0b5690f67e83262b7ac/no-code-editor.png",
    },
    {
      "title": <>Convert a Word Doc into a Decision Tree</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/25934987be22135c2de881756f5b5ca2/worddoctowebapp.png",
    },
    {
      "title": <>Convert a Spreadsheet into a Directory</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/d20fa1c4c493c06ffaad4f458f3c548c/spreadsheettowebapp.png",
    },
    {
      "title": <>Embed in Your Website</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/173072f78a18b8b7552bdfde2325b15e/embed-website.png",
    },
    {
      "title": <>Embed in SalesForce</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/a93799d670ea66c113fd2c9e6eb0148d/embed-salesforce.png",
    },
    {
      "title": <>Build / Share / Embed</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/4f55d3afc65ea13da97442ec68d6c93e/fiesta.png",
    }
  ],
}

const NoCodePlatform = () => {
  return (
    <Template content={content} />
  )
};
export default NoCodePlatform;
