import { useState, useEffect } from "react";
import useApiCall from "./useApiCall";

export default function useAppPatch(url: any) {
  const [patching, setPatching] = useState<any>(null);
  const [patchResults, setPatchResults] = useState<any>(null);
  const API = useApiCall();
  useEffect(() => {
    /* Send request */
    patching &&
      API(
        (response) => {
          const { body } = response;
          setPatching(null);
          setPatchResults(body);
        },
        "PATCH",
        `/v1/${url}`,
        null,
        patching
      );
  }, [patching]);
  return { patching, setPatching, patchResults, setPatchResults };
}
