import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Solve Knowledge Transfer and Scale Your Business",
  },
  "header": {
    "title": <>Solve Knowledge Transfer and <span>Scale</span> Your Business</>,
    "description": "Systematize the expertise of your top performers and turn new hires into instant experts.",
  },
  "main": [
    {
      "title": "Interactive Standard Operating Procedures",
      "description": "AI Generated product recommendation guides",
      "apps": [
        "/apps/66ae4ed0afb8636dcb8ece61", "/colorado_informed-how-to-vote-in-colorado"],
      "cta": ""
    },
  ],
}

const KnowledgeTransfer = () => {
  return (
    <Template content={content} />
  )
};
export default KnowledgeTransfer;
