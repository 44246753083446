import { useState, useEffect } from "react";
import useApiCall from "./useApiCall";

export default function useAppPost(url: any) {
  const [saving, setSaving] = useState<any>(null);
  const [saveResults, setSaveResults] = useState<any>(null);
  const API = useApiCall();
  useEffect(() => {
    /* Send request */
    saving &&
      API(
        (response) => {
          const { body } = response;
          setSaving(null);
          setSaveResults(body);
        },
        "POST",
        `/v1/${url}`,
        null,
        saving
      );
  }, [saving]);
  return { saving, setSaving, saveResults, setSaveResults };
}
