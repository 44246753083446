import styled from 'styled-components';
import { device } from "../../submodules/logictry_common/src/utils/device";
import Constants from '../../submodules/logictry_config/constants';

export default styled.section`
  padding: 4rem 0;
  margin: 2rem 10%;
  @media ${device.laptop} {
    text-align: center;
    margin: 2rem 1rem;
  }
  > div {
    box-shadow: 0 .9375rem 3.125rem 0 rgba(0,0,0,0.3);
    margin: 0 1rem;
    padding: 4rem 4rem;
    background: ${Constants.PrimaryColor};
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media ${device.laptop} {
      flex-direction: column;
      // text-align: center;
      padding: 3rem 2rem;
      gap: 2rem;
    }
    > div:first-child {
      color: white;
    }
    p {
      font-size: ${Constants.NormalFontSize};
    }
    > a:last-child {
      padding: 1rem 2rem;
      border-radius: 1rem;
      background: #f2f2f2;
      text-decoration: none;
      color: ${Constants.PrimaryColor};
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
`;
