import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Decision Trees"
  },
  "header": {
    "title": <><span>Interactive</span> Decision Trees</>,
    "description": "Harness AI to create interactive decision trees for various applications, including product recommendations and personalized guidance.",
    "app": "/brightonkaoma-what-sdg-are-you"
  },
  "main": [
    {
      "title": "Voting Guides",
      "description": "Create decision trees to provide voters with tailored instructions and information about voting procedures.",
      "apps": [
        "/apps/66ae4ed0afb8636dcb8ece61",
        "/colorado_informed-how-to-vote-in-colorado"
      ],
      "cta": ""
    },
    {
      "title": "Community Guides",
      "description": "Develop decision trees to offer personalized guidance for community members on topics like evaluating if a mastermind group is suitable for them.",
      "app": "/fyli-is-a-mastermind-group-right-for-me"
    },
    {
      "title": "Eligibility Guides",
      "description": "Create eligibility guides to assess qualification for various programs and grants.",
      "app": "/omnisync-startup-eligibility-for-us-federal-grants-assessment"
    }
  ],
  "caseStudies": [
    "colorado-informed-voting-surveys-and-wildfire-preparation-guides",
    "fyli-community-portal",
    "impact-sales-sales-decision-guides"
  ]
}

const DecisionTrees = () => {
  return (
    <Template content={content} />
  )
};
export default DecisionTrees;
