import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Filterable Curated Event Planners"
  },
  "header": {
    "title": <>Filterable <span>Curated</span> Event Planners</>,
    "description": "Utilize AI to create curated event planning tools tailored to your audience's needs.",
    "app": "/apps/640a28b76dfd410dbbe1e79b"
  },
  "main": [
    {
      "title": "Large Events",
      "description": "Create a powerful tool that helps attendees navigate large-scale events, such as SXSW, ensuring they maximize their experience.",
      "app": "/apps/5df7ed856ffd2d4c73a78659"
    },
    {
      "title": "Small Events",
      "description": "Design a specialized tool to curate and manage intimate local events, tailored for occasions like New Year's Eve.",
      "app": "/apps/63d843b8039fe961e12498e1"
    }
  ]
}

const EventPlanners = () => {
  return (
    <Template content={content} />
  )
};
export default EventPlanners;
