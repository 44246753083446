import React from "react";
import styled from "styled-components";
import { device } from "../../submodules/logictry_common/src/utils/device";

const DivWith625PaddingBottom = styled.div`
  position: relative;
  padding-bottom: 62.5%;
  @media ${device.laptop} {
    padding-bottom: 100%;
  }
  height: 0;
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 .9375rem 3.125rem 0 rgba(0,0,0,0.3);
`;
const TiledApps = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
  > div {
    box-shadow: 0 .9375rem 3.125rem 0 rgba(0,0,0,0.3);
    position: relative;
    padding-bottom: 60%;
    width: 100%;
    @media ${device.laptop} {
      width: 100%;
    }
    @media ${device.tablet} {
      padding-bottom: 100%;
    }
    border-radius: 0.5rem;
    overflow: hidden;
  }
`;
const Wrapper = styled.div`
  iframe {
    background: white;
  }
`;

const Content = (s, header) => {
  const origin = window.location.hostname.endsWith('localhost') ? 'https://localhost' : 'https://logictry.com';
  return <Wrapper>
    {s.apps && <TiledApps>
      {s.apps.map((app) => <div key={app}><iframe title={"App"} src={`${setDefaultQueryParams(origin + app)}`} allowFullScreen></iframe></div>)}
    </TiledApps>}
    {s.app && <DivWith625PaddingBottom><iframe title={"App"} src={`${setDefaultQueryParams(origin + s.app)}`} allowFullScreen></iframe></DivWith625PaddingBottom>}
    {s.image && <div style={{ position: 'relative', paddingBottom: '62.5%', height: 0 }}><img style={{ position: 'absolute', inset: 0, objectFit: header ? 'cover' : 'contain', width: '100%', height: '100%', borderRadius: 8 }} alt={s.title} src={s.image}></img></div>}
    {s.canva && <DivWith625PaddingBottom style={{ paddingBottom: '56.25%' }} dangerouslySetInnerHTML={{ __html: `<div style="position: relative; width: 100%; height: 0; padding-top: 56.25%; padding-bottom: 0; overflow: hidden; border-radius: 8px; will-change: transform;"><iframe loading="lazy" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;" src="${s.canva}" allowFullScreen="allowfullscreen" allow="fullscreen"></iframe></div>` }}></DivWith625PaddingBottom>}
    {s.loom && <div style={{ position: 'relative', paddingBottom: '62.5%', height: 0 }}><iframe src={s.loom} allowFullScreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', boxShadow: '0 .9375rem 3.125rem 0 rgba(0,0,0,0.3)', borderRadius: '1rem' }}></iframe></div>}
    {s.youtube && <div style={{ width: '100%', paddingBottom: '56.5%', position: 'relative' }}><iframe style={{ position: 'absolute', inset: 0, width: '100%', height: '100%', boxShadow: '0 .9375rem 3.125rem 0 rgba(0,0,0,0.3)', borderRadius: '1rem' }} src={`${s.youtube}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe></div>}
  </Wrapper>;
}
function setDefaultQueryParams(url) {
  const DEFAULT_QUERY_PARAMS = 'autoresize=false&autoscroll=false&allowredirects=true&allowfullscreen=true&onlyscrollfullscreen=false&onclickshowfullscreen=false&showplaceholder=false';
  const defaultParams = new URLSearchParams(DEFAULT_QUERY_PARAMS);
  const urlObj = new URL(url);
  const existingParams = urlObj.searchParams;
  defaultParams.forEach((value, key) => {
    if (!existingParams.has(key)) {
      existingParams.set(key, value);
    }
  });
  urlObj.search = existingParams.toString();
  return urlObj.toString();
}

export default Content;
