import React from "react";
import styled from "styled-components";
import Section from "../../styledhtml/Section";
import { device } from "../../submodules/logictry_common/src/utils/device";
import Constants from '../../submodules/logictry_config/constants';

export default styled(Section)`
  flex-direction: row;
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  align-items: center;
  @media ${device.laptop} {
    flex-direction: column;
    align-items: flex-start;
    &:nth-child(even) {
      flex-direction: column;
    }
  }
  > div:first-child {
    flex: 2;
    @media ${device.laptop} {
      max-width: 32rem;
      margin-top: 1rem;
    }
  }
  > div:last-child {
    flex: 5;
    @media ${device.laptop} {
      // max-width: 42rem;
    }
  }
`;
