import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Community Resources",
  },
  "header": {
    "title": "Interactive Community Resources",
    "description": <>Scale your community with <b>next generation interactive resources</b></>,
    "app": "/fiesta-service-providers"
  },
  "main": [
    {
      "title": "Custom Branded Directories",
      "description": "Fiesta curated a directory of austin accelerators and incubators",
      "app": "/fiesta-austin-accelerators-and-incubators"
    },
    {
      "title": "Voting Guides",
      "description": "Colorado Informed built interactive personalized voting instructions",
      "app": "/colorado_informed-how-to-vote-in-colorado"
    },
    {
      "title": "Eligibility Guides",
      "description": "OmniSync built an eligibility guide for Federal Grants",
      "app": "/omnisync-startup-eligibility-for-us-federal-grants-assessment"
    },
    {
      "title": "Document Generators",
      "description": "Y Combinator built a legal document generator for their FAST agreement",
      "app": "/ycombinator-safe-document-generator"
    },
    {
      "title": "Decision Guides",
      "description": "Fyli built a tool to help their community decide if a mastermind group is right",
      "app": "/fyli-is-a-mastermind-group-right-for-me"
    },
    {
      "title": "Investor Matrix",
      "description": "Fyli built a tool to help their community decide if a mastermind group is right",
      "app": "/apps/642ee4383705a00dbdace397"
    },
  ],
}

const ExpertCommunities = () => {
  return (
    <Template content={content} />
  )
};
export default ExpertCommunities;
